import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit { 
  email: string;
  password: string;
  error_msg: string;

  constructor(private authService: AuthService) { }

  ngOnInit() { }

  signIn() {
    this.authService.emailSignIn(this.email, this.password)
      .catch(err => this.error_msg = err.message);
  }
}
